import { TChatWidgetId } from '../../store-chat-widget';
import { IFloatStackProps } from './float-stack.type';

export interface IFloatStackChildren {
  className?: string;
  id?: string;
  content: React.ReactNode;
}

export interface IFloatStackChildrenProps
  extends IFloatStackChildren,
    Pick<IFloatStackProps, 'duration' | 'animationDuration' | 'animationInClass' | 'animationOutClass'> {
  widgetId: TChatWidgetId;
  parentId: string;
  canRemove?: boolean;
}
