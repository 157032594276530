import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import { forwardRef, useImperativeHandle } from 'react';
import Draggable from 'react-draggable';

import { ChatWidgetStore } from '../../store-chat-widget';
import './float.style.scss';
import { IFloatProps, IFloatRef } from './float.type';

const classNamePrefix = 'tt-ui-chat-widget__float';

export const Float = observer(
  forwardRef<IFloatRef, IFloatProps>((props, ref) => {
    const {
      widgetId,
      id,
      className,
      verticalAlign = 'bottom',
      horizonAlign = 'right',
      children,
      draggable,
      onDrag,
      onDragStart,
      onDragStop
    } = props;

    useImperativeHandle(ref, () => {
      return {
        remove: () => ChatWidgetStore.removeFloatAction(widgetId, id)
      };
    }, [widgetId, id]);

    return (
      <Draggable disabled={!draggable} onStart={onDragStart} onDrag={onDrag} onStop={onDragStop}>
        <div className={clsx(classNamePrefix, className, `vertical-align-${verticalAlign}`, `horizon-align-${horizonAlign}`)} id={id}>
          {children}
        </div>
      </Draggable>
    );
  })
);
