import { clsx } from 'clsx';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { dayjs } from '@totopkg/shared-util-date-time';

import { ChatAvatar } from '../chat-avatar/chat-avatar.component';
import './message.style.scss';
import { IMessageProps } from './message.type';

const classNamePrefix = 'tt-ui-chat-widget__message';

export const Message = memo((props: IMessageProps) => {
  const {
    index,
    className,
    style,
    message = [],
    inlineMode = false,
    position = 'left',
    enableTimeAgo = true,
    timeFormat = 'HH:mm',
    messageRender,
    setHeightByIndex
  } = props;

  const messageRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>();

  const _messageList = useMemo(() => {
    return Array.isArray(message) ? message : [message];
  }, [message]);

  const { avatar, username, time } = useMemo(() => _messageList[Math.max(0, _messageList.length - 1)], [_messageList]);

  useEffect(() => {
    if (index == null) return;

    const measureHeight = () => {
      if (messageRef.current) {
        const _height = messageRef.current.clientHeight;
        setHeight(_height);
        setHeightByIndex?.(index, _height);
      } else {
        // Retry after a short delay if the element is not yet rendered
        requestAnimationFrame(measureHeight);
      }
    };

    // Measure height once component is mounted
    measureHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_messageList, index]);

  return (
    <div
      className={clsx(classNamePrefix, className, `position-${position}`, {
        inline: inlineMode
      })}
      style={{
        ...style,
        left: 8,
        right: 8,
        width: 'calc(100% - 16px)',
        maxWidth: 'calc(100% - 16px)',
        height: height != null ? height : undefined
      }}
      ref={messageRef}
    >
      {avatar && <ChatAvatar {...avatar} className={clsx(`${classNamePrefix}__avatar`, avatar.className)} />}

      <div className={`${classNamePrefix}__content-wrapper`}>
        {username && <div className={`${classNamePrefix}__username`}>{username}</div>}

        {_messageList.length > 0 && (
          <div className={`${classNamePrefix}__content-list`}>
            {_messageList.map((messageItem, index) => {
              const { localId = index, content } = messageItem;

              return (
                <div className={`${classNamePrefix}__content-item`} key={localId}>
                  <div
                    className={`${classNamePrefix}__content-item-inner`}
                    dangerouslySetInnerHTML={
                      messageRender == null && content
                        ? {
                            __html: content
                          }
                        : undefined
                    }
                  >
                    {messageRender?.(messageItem)}
                  </div>

                  {index + 1 === _messageList.length && time && (
                    <div className={`${classNamePrefix}__time`}>
                      {enableTimeAgo ? (dayjs(time) as any).fromNow() : (dayjs(time) as any).format(timeFormat)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});
