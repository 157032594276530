import { TChatWidgetId } from '../../store-chat-widget';
import { IFloatProps } from '../float/float.type';
import { IFloatStackChildren } from './float-stack-children.type';

export interface IFloatStackProps extends Omit<IFloatProps, 'children'> {
  widgetId: TChatWidgetId;
  children?: IFloatStackChildren | IFloatStackChildren[];

  animationInClass?: string;
  animationOutClass?: string;
  animationDuration?: number;

  /** default: 1 */
  maxItemsPerView?: number;

  /** if undefined, alway display */
  duration?: number;
}
