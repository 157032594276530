import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { IFloatStackChildren } from '../../components/float-stack/float-stack-children.type';
import { floatStackSelector } from '../selector/float-stack.selector';
import { getChatWidgetStore } from '../store/chat-widget.store';
import { TChatWidgetId, TFloatId } from '../store/chat-widget.type';

export const addFloatStackChildrenAction = mutatorAction(
  'addFloatStackChildrenAction',
  (widgetId: TChatWidgetId, floatId: TFloatId, children: IFloatStackChildren | IFloatStackChildren[]) => {
    const prevFloat = floatStackSelector(widgetId, floatId);

    if (!prevFloat) return;

    const { children: floatChildren = [] } = prevFloat;
    const prevChildren = Array.isArray(floatChildren) ? floatChildren : [floatChildren];
    const newChildren = [children ?? []].flat().map(item => ({
      ...item,
      id: item.id ?? performance.now().toString()
    }));

    let widgetFloatStackMap = getChatWidgetStore().floatStacks.get(widgetId);
    if (widgetFloatStackMap == null) {
      widgetFloatStackMap = new ObservableMap();
      getChatWidgetStore().floatStacks.set(widgetId, widgetFloatStackMap);
    }

    widgetFloatStackMap.set(floatId, {
      ...prevFloat,
      children: [...prevChildren, ...newChildren]
    });
  }
);
