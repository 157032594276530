import { mutatorAction } from 'satcheljs';

import { getChatWidgetStore } from '../store/chat-widget.store';
import { TChatWidgetId, TFloatId } from '../store/chat-widget.type';

export const removeFloatAction = mutatorAction('removeFloatAction', (widgetID: TChatWidgetId, floatId: TFloatId | TFloatId[]) => {
  const widgetFloatMap = getChatWidgetStore().floats.get(widgetID);
  if (!widgetFloatMap?.size) return;

  if (Array.isArray(floatId)) {
    floatId.forEach(id => {
      widgetFloatMap.delete(id);
    });
  } else {
    widgetFloatMap.delete(floatId);
  }
});
