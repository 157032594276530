import { mutatorAction } from 'satcheljs';

import { floatStackSelector } from '../export';
import { getChatWidgetStore } from '../store/chat-widget.store';
import { TChatWidgetId, TFloatId } from '../store/chat-widget.type';

export const removeFloatStackChildrenAction = mutatorAction(
  'removeFloatStackChildrenAction',
  (widgetId: TChatWidgetId, floatId: TFloatId, childrenId: string | string[]) => {
    const float = floatStackSelector(widgetId, floatId);

    if (!float) return;

    const widgetFloatStackMap = getChatWidgetStore().floatStacks.get(widgetId);
    if (!widgetFloatStackMap?.size) return;

    const removeChildrenIds = [childrenId].flat();
    const remainChildren = [float.children ?? []].flat().filter(item => item.id && !removeChildrenIds.includes(item.id));

    widgetFloatStackMap.set(floatId, {
      ...float,
      children: remainChildren
    });
  }
);
