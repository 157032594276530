import { TChatWidgetId } from '../../store-chat-widget';
import { IChatHeaderProps } from '../chat-header/chat-header.type';
import { IMessageInputProps } from '../message-input/message-input.type';
import { IMessageProps } from '../message/message.type';

export interface IChatWidgetRef {
  scrollToBottom: () => void;
  getInputValue: () => string;
  setInputValue: (value: string) => void;
}

export interface IChatWidgetProps {
  id: TChatWidgetId;

  className?: string;
  children?: React.ReactNode;
  autoScrollBottomThreshold?: number;
  animatedOnInit?: boolean;
  header?: IChatHeaderProps;

  messages?: IMessageProps[];
  messageRender?: IMessageProps['messageRender'];

  enableEmoji?: boolean;
  onSubmit?: IMessageInputProps['onSubmit'];

  hideInput?: boolean;
  menu?: IMessageInputProps['menu'];
  inputHeadChildren?: IMessageInputProps['headChildren'];
  inputTailChildren?: IMessageInputProps['tailChildren'];
  inputPlaceholder?: IMessageInputProps['placeholder'];

  onInputChange?: (value: string) => void;
}
