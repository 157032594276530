import { clsx } from 'clsx';
import { computed, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';

import { Float } from '../float/float.component';
import { IFloatRef } from '../float/float.type';
import { FloatStackChildren } from './float-stack-children.component';
import './float-stack.style.scss';
import { IFloatStackProps } from './float-stack.type';

const classNamePrefix = 'tt-ui-chat-widget__float-stack';

export const FloatStack = observer(
  forwardRef<IFloatRef, IFloatStackProps>((props, ref) => {
    const {
      widgetId,
      id: parentId,
      className,
      animationInClass,
      animationOutClass,
      animationDuration,
      maxItemsPerView = 1,
      duration,
      children,
      ...baseProps
    } = props;

    const _children = computed(() => [children ?? []].flat()).get();

    return (
      <Float {...baseProps} widgetId={widgetId} id={parentId} className={clsx(classNamePrefix, className)} ref={ref}>
        {_children.map((item, index) => {
          const { id = index.toString() } = item;

          const _canRemove = _children.length > maxItemsPerView && index < _children.length - maxItemsPerView;

          return (
            <FloatStackChildren
              widgetId={widgetId}
              key={id}
              parentId={parentId}
              duration={duration}
              animationDuration={animationDuration}
              animationInClass={animationInClass}
              animationOutClass={animationOutClass}
              canRemove={_canRemove}
              {...toJS(item)}
              className={`${classNamePrefix}__children`}
            />
          );
        })}
      </Float>
    );
  })
);
