import { DraggableEventHandler } from 'react-draggable';

import { TChatWidgetId } from '../../store-chat-widget';

export interface IFloatRef {
  remove: () => void;
}

export interface IFloatProps {
  widgetId: TChatWidgetId;
  id: string;
  className?: string;
  verticalAlign?: 'top' | 'center' | 'bottom';
  horizonAlign?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
  draggable?: boolean;
  onDragStart?: DraggableEventHandler;
  onDragStop?: DraggableEventHandler;
  onDrag?: DraggableEventHandler;
}
