import clsx from 'clsx';
import { Fragment, useMemo } from 'react';

import './chat-header.style.scss';
import { IChatHeaderProps } from './chat-header.type';

const classNamePrefix = 'tt-ui-chat-widget__chat-header';

export const ChatHeader: React.FC<IChatHeaderProps> = props => {
  const { className, title = 'Chat room', subTitle, headChildren, tailChildren } = props;

  const _headChildren = useMemo(() => {
    return Array.isArray(headChildren) ? headChildren : [headChildren];
  }, [headChildren]);

  const _tailChildren = useMemo(() => {
    return Array.isArray(tailChildren) ? tailChildren : [tailChildren];
  }, [tailChildren]);

  return (
    <div className={clsx(classNamePrefix, className)}>
      {_headChildren.length > 0 && (
        <div className={`${classNamePrefix}__head-wrapper`}>
          {_headChildren.map((headChild, index) => {
            return <Fragment key={index}>{headChild}</Fragment>;
          })}
        </div>
      )}

      <div className={`${classNamePrefix}__title-wrapper`}>
        {title && <div className={`${classNamePrefix}__title`}>{title}</div>}
        {subTitle && <div className={`${classNamePrefix}__sub-title`}>{subTitle}</div>}
      </div>

      {_tailChildren.length > 0 && (
        <div className={`${classNamePrefix}__tail-wrapper`}>
          {_tailChildren.map((tailChild, index) => {
            return <Fragment key={index}>{tailChild}</Fragment>;
          })}
        </div>
      )}
    </div>
  );
};
