import { mutatorAction } from 'satcheljs';

import { dialogSelector } from '../selector/dialog.selector';
import { getChatWidgetStore } from '../store/chat-widget.store';
import { TChatWidgetId, TDialogId } from '../store/chat-widget.type';

export const closeDialogAction = mutatorAction('closeDialogAction', (widgetId: TChatWidgetId, dialogId: TDialogId) => {
  const dialog = dialogSelector(widgetId, dialogId);

  if (!dialog) return;

  getChatWidgetStore()
    .dialogs.get(widgetId)
    ?.set(dialogId, { ...dialog, isOpen: false });
});
