import { CSSProperties } from 'react';

import { IChatAvatarProps } from '../chat-avatar/chat-avatar.type';

export interface IMessage {
  id?: string;
  avatar?: IChatAvatarProps;
  username?: string;
  localId?: string;
  content?: string;
  time?: string;
}

export interface IMessageProps {
  index?: number;
  id?: string;
  className?: string;
  style?: CSSProperties;
  message?: IMessage | IMessage[];
  inlineMode?: boolean;
  timeFormat?: string;
  enableTimeAgo?: boolean;
  position?: 'left' | 'right';

  messageRender?: (message?: IMessage) => React.ReactNode;
  setHeightByIndex?: (index: number, height: number) => void;
}
