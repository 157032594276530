import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { IDialogProps } from '../../components/dialog/dialog.type';
import { TChatWidgetId } from '../export';
import { getChatWidgetStore } from '../store/chat-widget.store';

export const addDialogAction = mutatorAction('addDialogAction', (widgetId: TChatWidgetId, dialog: IDialogProps | IDialogProps[]) => {
  if (!widgetId) return;

  let widgetDialogMap = getChatWidgetStore().dialogs.get(widgetId);
  if (widgetDialogMap == null) {
    widgetDialogMap = new ObservableMap();
    getChatWidgetStore().dialogs.set(widgetId, widgetDialogMap);
  }

  if (Array.isArray(dialog)) {
    dialog.forEach(item => {
      widgetDialogMap.set(item.id, item);
    });
  } else {
    widgetDialogMap.set(dialog.id, dialog);
  }
});
