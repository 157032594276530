import emojiData from '@emoji-mart/data';
import EmojiPicker from '@emoji-mart/react';
import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { ChatWidgetStore } from '../../store-chat-widget';
import { insertTextAtCaret } from '../../util';
import { ButtonGhostPrimary } from '../button-ghost-primary/button-ghost-primary.component';
import { ButtonGhost } from '../button-ghost/button-ghost.component';
import { Menu } from '../menu/menu.component';
import './message-input.style.scss';
import { IMessageInputProps, IMessageInputRef } from './message-input.type';

const classNamePrefix = 'tt-ui-chat-widget__message-input';

const EMOJI_DIALOG_ID = 'tt-ui-chat-widget__message-input-emoji-widget';

export const MessageInput = observer(
  forwardRef<IMessageInputRef, IMessageInputProps>((props, ref) => {
    const {
      widgetId,
      className,
      placeholder = 'Input message...',
      headChildren = [],
      tailChildren = [],
      enableEmoji = false,
      onSubmit,
      menu,
      onChange
    } = props;

    const editorPrevValueRef = useRef<string>();
    const editorRef = useRef<HTMLDivElement>(null);
    const emojiPickerDialog = ChatWidgetStore.dialogSelector(widgetId, EMOJI_DIALOG_ID);

    const _headChildren = useMemo(() => {
      return Array.isArray(headChildren) ? headChildren : [headChildren];
    }, [headChildren]);

    const _tailChildren = useMemo(() => {
      return Array.isArray(tailChildren) ? tailChildren : [tailChildren];
    }, [tailChildren]);

    const _onSubmit = useCallback(() => {
      try {
        if (!editorRef.current || editorRef.current.innerText.trim().length === 0) return;

        onSubmit?.(editorRef.current.innerHTML);

        if (enableEmoji && emojiPickerDialog?.isOpen) {
          ChatWidgetStore.closeDialogAction(widgetId, EMOJI_DIALOG_ID);
        }
      } catch (error) {
        console.log('[Chat input] onSubmit:', error);
      } finally {
        if (editorRef.current) editorRef.current.innerHTML = '';
      }
    }, [widgetId, emojiPickerDialog?.isOpen, enableEmoji, onSubmit]);

    const _onChange = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.currentTarget.innerText.trim().length === 0) return;

        if (e.currentTarget.innerHTML !== editorPrevValueRef.current) {
          onChange?.(e.currentTarget.innerHTML);
          editorPrevValueRef.current = e.currentTarget.innerHTML;
        }
      },
      [onChange]
    );

    useImperativeHandle(ref, () => {
      return {
        getValue: () => editorRef.current?.innerHTML ?? '',
        setValue: value => {
          if (editorRef.current) {
            editorRef.current.innerHTML = value;
          }
        }
      };
    }, []);

    /** enable emoji picker */
    useEffect(() => {
      if (!enableEmoji) return;

      ChatWidgetStore.addDialogAction(widgetId, {
        widgetId,
        id: EMOJI_DIALOG_ID,
        onOpen: () => {
          editorRef.current?.focus();
        },
        children: (
          <EmojiPicker
            data={emojiData}
            onEmojiSelect={(data: any) => {
              insertTextAtCaret(data?.native ?? '');
            }}
            icons="outline"
            previewPosition="none"
            searchPosition="none"
          />
        ),
        verticalAlign: 'bottom'
      });
    }, [widgetId, enableEmoji]);

    /** add menu dialog */
    useEffect(() => {
      //
    }, []);

    return (
      <div className={clsx(classNamePrefix, className)}>
        <div className={`${classNamePrefix}__head-wrapper`}>
          {menu != null && <Menu {...menu} />}

          {_headChildren.map((headChild, index) => {
            return <React.Fragment key={index}>{headChild}</React.Fragment>;
          })}
        </div>

        <div
          className={`${classNamePrefix}__editor`}
          ref={editorRef}
          contentEditable
          data-placeholder={placeholder}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              _onSubmit();
            }
          }}
          onKeyUp={e => {
            _onChange(e);
          }}
          onMouseDown={() => {
            if (enableEmoji && emojiPickerDialog?.isOpen) {
              ChatWidgetStore.closeDialogAction(widgetId, EMOJI_DIALOG_ID);
            }
          }}
        />

        <div className={`${classNamePrefix}__tail-wrapper`}>
          {_tailChildren.map((tailChild, index) => {
            return <React.Fragment key={index}>{tailChild}</React.Fragment>;
          })}

          {enableEmoji && (
            <ButtonGhost
              onClick={() => {
                if (emojiPickerDialog?.isOpen) {
                  ChatWidgetStore.closeDialogAction(widgetId, EMOJI_DIALOG_ID);
                } else {
                  ChatWidgetStore.openDialogAction(widgetId, EMOJI_DIALOG_ID);
                }
              }}
            >
              <span className="material-symbols-outlined icon">mood</span>
            </ButtonGhost>
          )}

          <ButtonGhostPrimary onClick={() => _onSubmit()}>
            <span className="material-symbols-outlined icon">Send</span>
          </ButtonGhostPrimary>
        </div>
      </div>
    );
  })
);
