import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { computedFn } from 'mobx-utils';

import { ChatWidgetStore } from '../../store-chat-widget';
import { IFloatProps } from '../float/float.type';
import './float-stack-manager.style.scss';
import { IFloatStackManagerProps } from './float-stack-manager.type';
import { FloatStack } from './float-stack.component';

const classNamePrefix = 'tt-ui-chat-widget__float-stack-manager';

export const FloatStackManager: React.FC<IFloatStackManagerProps> = observer(props => {
  const { widgetId } = props;

  const floats = ChatWidgetStore.floatStacksSelector(widgetId);

  const getFloatByPosition = computedFn((verticalAlign: IFloatProps['verticalAlign'], horizonAlign: IFloatProps['horizonAlign']) => {
    return toJS(floats).filter(item => {
      const { verticalAlign: itemVerticalAlign = 'bottom', horizonAlign: itemHorizonAlign = 'right' } = item;

      return itemVerticalAlign === verticalAlign && itemHorizonAlign === horizonAlign;
    });
  });

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__top-left-wrapper`}>
        {getFloatByPosition('top', 'left').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__top-center-wrapper`}>
        {getFloatByPosition('top', 'center').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__top-right-wrapper`}>
        {getFloatByPosition('top', 'right').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__center-left-wrapper`}>
        {getFloatByPosition('center', 'left').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__center-center-wrapper`}>
        {getFloatByPosition('center', 'center').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__center-right-wrapper`}>
        {getFloatByPosition('center', 'right').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__bottom-left-wrapper`}>
        {getFloatByPosition('bottom', 'left').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__bottom-center-wrapper`}>
        {getFloatByPosition('bottom', 'center').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
      <div className={`${classNamePrefix}__bottom-right-wrapper`}>
        {getFloatByPosition('bottom', 'right').map((float, index) => (
          <FloatStack {...toJS(float)} key={float.id ?? index} />
        ))}
      </div>
    </div>
  );
});
