import { TChatWidgetId } from '../../store-chat-widget';
import { IMenuProps } from '../menu/menu.type';

export interface IMessageInputProps {
  widgetId: TChatWidgetId;
  className?: string;
  placeholder?: string;
  headChildren?: React.ReactNode[] | React.ReactNode;
  tailChildren?: React.ReactNode[] | React.ReactNode;

  enableEmoji?: boolean;
  menu?: IMenuProps;
  onSubmit?: (content: string | undefined) => void;
  onChange?: (value: string) => void;
}

export interface IMessageInputRef {
  getValue: () => string;
  setValue: (value: string) => void;
}
