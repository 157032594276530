import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { ChatWidgetStore } from '../../store-chat-widget';
import { IDialogManagerProps } from './dialog-manager.type';
import { Dialog } from './dialog.component';

const classNamePrefix = 'tt-chat-widget__dialog-manager';

export const DialogManager: React.FC<IDialogManagerProps> = observer(props => {
  const { widgetId } = props;

  const dialogs = ChatWidgetStore.dialogsSelector(widgetId);

  return (
    <div className={classNamePrefix}>
      {dialogs.map((dialog, index) => {
        return <Dialog {...toJS(dialog)} key={index} />;
      })}
    </div>
  );
});
