import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { IFloatStackProps } from '../../components/float-stack/float-stack.type';
import { TChatWidgetId } from '../export';
import { getChatWidgetStore } from '../store/chat-widget.store';

export const addFloatStackAction = mutatorAction(
  'addFloatStackAction',
  (widgetId: TChatWidgetId, float: IFloatStackProps | IFloatStackProps[]) => {
    let widgetFloatStackMap = getChatWidgetStore().floatStacks.get(widgetId);

    if (widgetFloatStackMap == null) {
      widgetFloatStackMap = new ObservableMap();
      getChatWidgetStore().floatStacks.set(widgetId, widgetFloatStackMap);
    }

    if (Array.isArray(float)) {
      float.forEach(item => {
        widgetFloatStackMap.set(item.id, item);
      });
    } else {
      widgetFloatStackMap.set(float.id, float);
    }
  }
);
