import { ObservableMap } from 'mobx';

import { IDialogProps } from '../../components/dialog/dialog.type';
import { IFloatStackProps } from '../../components/float-stack/float-stack.type';
import { IFloatProps } from '../../components/float/float.type';

export type TChatWidgetId = string;
export type TDialogId = string;
export type TFloatId = string;

export interface IChatWidgetStore {
  dialogs: ObservableMap<TChatWidgetId, ObservableMap<TDialogId, IDialogProps>>;
  floats: ObservableMap<TChatWidgetId, ObservableMap<TFloatId, IFloatProps>>;
  floatStacks: ObservableMap<TChatWidgetId, ObservableMap<TFloatId, IFloatStackProps>>;
}
