export interface IButtonProps {
  className?: string;
  style?: React.CSSProperties;

  children?: React.ReactNode;
  headChild?: React.ReactNode;
  tailChild?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  darkMode?: boolean;

  onClick?: (e: React.MouseEvent) => void;
}
