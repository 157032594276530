import { CSSProperties } from 'react';

export interface IMenuItemProps {
  className?: string;
  style?: CSSProperties;
  id?: string;
  headChild?: React.ReactNode;
  title?: string;
  description?: string;
  tailChild?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}
