import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ChatWidgetStore } from '../../store-chat-widget';
import './dialog.style.scss';
import { IDialogProps, IDialogRef } from './dialog.type';

const classNamePrefix = 'tt-chat-widget__dialog';

export const Dialog = observer(
  forwardRef<IDialogRef, IDialogProps>((props, ref) => {
    const {
      widgetId,
      id,
      className,
      children,
      verticalAlign = 'center',
      isOpen,
      onOpen,
      onDismiss,
      onDidDismiss,
      animationInClassName = 'animate__fadeInUp',
      animationOutClassName = 'animate__fadeOutDown',
      animationDuration = 500,
      backdropDismiss = true
    } = props;

    const closeTimeoutRef = useRef<ReturnType<typeof setInterval>>();
    const [isClosed, setIsClosed] = useState<boolean>(true);
    const [animationClass, setAnimationClass] = useState<string>();

    const dialogInStore = ChatWidgetStore.dialogSelector(widgetId, id);

    const open = useCallback(() => {
      setIsClosed(false);
      setAnimationClass(animationInClassName);
      onOpen?.();
    }, [animationInClassName, onOpen]);

    const close = useCallback(() => {
      setAnimationClass(animationOutClassName);
      onDismiss?.();

      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }

      closeTimeoutRef.current = setTimeout(() => {
        onDidDismiss?.();
        setIsClosed(true);
      }, animationDuration);
    }, [animationDuration, animationOutClassName, onDidDismiss, onDismiss]);

    useImperativeHandle(ref, () => {
      return {
        open,
        close
      };
    });

    const onBackdropDismiss = useCallback(() => {
      if (!backdropDismiss || !isOpen) return;

      if (dialogInStore) {
        ChatWidgetStore.closeDialogAction(widgetId, id);
      } else {
        close();
      }
    }, [backdropDismiss, close, dialogInStore, widgetId, id, isOpen]);

    useEffect(() => {
      if (isOpen == null) return;

      if (isOpen) {
        open();
      } else {
        close();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
      <div
        className={clsx(classNamePrefix, className, {
          closed: isClosed,
          backdrop: backdropDismiss
        })}
        id={id}
        data-vertical-align={verticalAlign}
        onClick={() => {
          onBackdropDismiss();
        }}
      >
        <div
          className={clsx(`${classNamePrefix}__content-wrapper`, 'animate__animated', animationClass)}
          style={{
            animationDuration: `${animationDuration}ms`
          }}
          onClick={e => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    );
  })
);
