import { TChatWidgetId } from '../../store-chat-widget';

export interface IDialogRef {
  open: () => void;
  close: () => void;
}

export interface IDialogProps {
  widgetId: TChatWidgetId;
  id: string;
  className?: string;
  verticalAlign?: 'top' | 'center' | 'bottom';
  children?: React.ReactNode;
  isOpen?: boolean;

  onOpen?: () => void;
  onDismiss?: () => void;
  onDidDismiss?: () => void;

  animationInClassName?: string;
  animationOutClassName?: string;
  animationDuration?: number;
  backdropDismiss?: boolean;
}
