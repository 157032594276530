import { clsx } from 'clsx';

import './button.style.scss';
import { IButtonProps } from './button.type';

const classNamePrefix = 'tt-ui-chat-widget__button';

export const Button: React.FC<IButtonProps> = props => {
  const { className, headChild, children, tailChild, size = 'medium', disabled, darkMode, onClick } = props;

  return (
    <button
      className={clsx(classNamePrefix, className, size, {
        'dark-mode': darkMode
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {headChild && <div className={`${classNamePrefix}__head-wrapper`}>{headChild}</div>}

      {children && <div className={`${classNamePrefix}__inner-content`}>{children}</div>}

      {tailChild && <div className={`${classNamePrefix}__tail-wrapper`}>{tailChild}</div>}
    </button>
  );
};
