import { clsx } from 'clsx';

import './menu-item.style.scss';
import { IMenuItemProps } from './menu-item.type';

const classNamePrefix = 'tt-ui-chat-widget__menu-item';

export const MenuItem: React.FC<IMenuItemProps> = props => {
  const { className, style, id, title, description, headChild, tailChild, onClick } = props;

  return (
    <div
      className={clsx(classNamePrefix, className, {
        actionable: onClick != null
      })}
      id={id}
      style={style}
      onClick={onClick}
    >
      {headChild && <div className={`${classNamePrefix}__head-wrapper`}>{headChild}</div>}

      <div className={`${classNamePrefix}__inner-content`}>
        {title && <div className={`${classNamePrefix}__title`}>{title}</div>}

        {description && <div className={`${classNamePrefix}__description`}>{description}</div>}
      </div>

      {tailChild && <div className={`${classNamePrefix}__tail-wrapper`}>{tailChild}</div>}
    </div>
  );
};
