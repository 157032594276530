import { CSSProperties } from 'react';

import { TChatWidgetId } from '../../store-chat-widget';
import { IMenuItemProps } from '../menu-item/menu-item.type';

export interface IMenuProps {
  widgetId: TChatWidgetId;

  id?: string;
  className?: string;
  style?: CSSProperties;

  dialogClassName?: string;

  items?: IMenuItemProps[];
  children?: React.ReactNode;
  toggleButton?: React.ReactNode;
  onOpen?: () => void;
  onDismiss?: () => void;
  onDidDismiss?: () => void;
}
