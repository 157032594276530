import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { IFloatProps } from '../../components/float/float.type';
import { TChatWidgetId } from '../export';
import { getChatWidgetStore } from '../store/chat-widget.store';

export const addFloatAction = mutatorAction('addFloatAction', (widgetId: TChatWidgetId, float: IFloatProps | IFloatProps[]) => {
  let widgetFloatMap = getChatWidgetStore().floats.get(widgetId);
  if (widgetFloatMap == null) {
    widgetFloatMap = new ObservableMap();
    getChatWidgetStore().floats.set(widgetId, widgetFloatMap);
  }

  if (Array.isArray(float)) {
    float.forEach(item => {
      widgetFloatMap.set(item.id, item);
    });
  } else {
    widgetFloatMap.set(float.id, float);
  }
});
