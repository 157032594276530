import { mutatorAction } from 'satcheljs';

import { getChatWidgetStore } from '../store/chat-widget.store';
import { TChatWidgetId, TFloatId } from '../store/chat-widget.type';

export const removeFloatStackAction = mutatorAction('removeFloatStackAction', (widgetId: TChatWidgetId, floatId: TFloatId | TFloatId[]) => {
  const widgetFloatStackMap = getChatWidgetStore().floatStacks.get(widgetId);
  if (!widgetFloatStackMap?.size) return;

  if (Array.isArray(floatId)) {
    floatId.forEach(key => {
      widgetFloatStackMap.delete(key);
    });
  } else {
    widgetFloatStackMap.delete(floatId);
  }
});
