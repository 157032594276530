import { mutatorAction } from 'satcheljs';

import { getChatWidgetStore } from '../store/chat-widget.store';
import { TChatWidgetId, TDialogId } from '../store/chat-widget.type';

export const removeDialogAction = mutatorAction('removeDialogAction', (widgetId: TChatWidgetId, dialogId: TDialogId | TDialogId[]) => {
  const widgetDialogMap = getChatWidgetStore().dialogs.get(widgetId);

  if (!widgetDialogMap?.size) return;

  if (Array.isArray(dialogId)) {
    dialogId.forEach(id => {
      widgetDialogMap.delete(id);
    });
  } else {
    widgetDialogMap.delete(dialogId);
  }
});
