import { clsx } from 'clsx';
import { CSSProperties } from 'react';

import './chat-avatar.style.scss';
import { IChatAvatarProps } from './chat-avatar.type';

const classNamePrefix = 'tt-ui-chat-widget__chat-avatar';

export const ChatAvatar: React.FC<IChatAvatarProps> = props => {
  const { className, avatarUrl, placeholderUrl = '', size = '32px', round = '100%', onClick } = props;

  return (
    <div
      className={clsx(classNamePrefix, className)}
      data-size={size}
      data-round={round}
      style={
        {
          '--size': size,
          '--round': round
        } as CSSProperties
      }
      onClick={onClick}
    >
      <img
        className={`${classNamePrefix}__picture`}
        src={avatarUrl}
        alt="avatar"
        onError={e => {
          if (e.currentTarget.src !== placeholderUrl) {
            e.currentTarget.src = placeholderUrl;
          }
        }}
      />
    </div>
  );
};
