import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ChatWidgetStore } from '../../store-chat-widget';
import { IFloatStackChildrenProps } from './float-stack-children.type';

const classNamePrefix = 'tt-ui-chat-widget__float-stack-children';

export const FloatStackChildren: React.FC<IFloatStackChildrenProps> = observer(props => {
  const {
    widgetId,
    className,
    parentId,
    id,
    content,
    animationDuration = 500,
    duration,
    animationInClass = 'animate__fadeInUp',
    animationOutClass = 'animate__fadeOutUp',
    canRemove
  } = props;

  const selfRef = useRef<HTMLDivElement>(null);
  const [animationClass, setAnimationClass] = useState<string>(animationInClass);

  const removeSelf = useCallback(() => {
    setAnimationClass(animationOutClass);

    setTimeout(() => {
      if (!id) {
        selfRef.current?.remove();
        return;
      }

      ChatWidgetStore.removeFloatStackChildrenAction(widgetId, parentId, id);
    }, animationDuration);
  }, [widgetId, animationDuration, animationOutClass, id, parentId]);

  // ** self remove by duration check
  useEffect(() => {
    if (duration) {
      const timeout = setTimeout(() => {
        removeSelf();
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [duration, removeSelf]);

  // ** self remove by force canRemove
  useEffect(() => {
    if (canRemove) {
      removeSelf();
    }
  }, [canRemove, removeSelf]);

  return (
    <div
      id={id}
      className={clsx(classNamePrefix, className, animationClass)}
      style={{
        animationDuration: `${animationDuration}ms`,
        position: animationClass === animationOutClass ? 'absolute' : 'relative'
      }}
      ref={selfRef}
    >
      {content}
    </div>
  );
});
