import { clsx } from 'clsx';

import { Button } from '../button/button.component';
import { IButtonProps } from '../button/button.type';
import './button-ghost.style.scss';

const classNamePrefix = 'tt-ui-chat-widget__button-ghost';

export const ButtonGhost: React.FC<IButtonProps> = props => {
  const { className } = props;

  return <Button {...props} className={clsx(classNamePrefix, className)} />;
};
