import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { TranslatedMessage } from '@totopkg/shared-util-language';

import { ChatWidgetStore } from '../../store-chat-widget';
import { ButtonPrimary } from '../button-primary/button-primary.component';
import { MenuItem } from '../menu-item/menu-item.component';
import './menu.style.scss';
import { IMenuProps } from './menu.type';

const classNamePrefix = 'tt-ui-chat-widget__menu';

export const Menu: React.FC<IMenuProps> = observer(props => {
  const {
    widgetId,
    id = classNamePrefix,
    className,
    dialogClassName = `${classNamePrefix}__dialog`,
    style,
    items,
    toggleButton,
    children,
    onOpen,
    onDismiss,
    onDidDismiss
  } = props;

  const menuDialog = ChatWidgetStore.dialogSelector(widgetId, id);

  const toggleOpenDialog = useCallback(() => {
    if (!menuDialog) return;

    if (menuDialog.isOpen) {
      ChatWidgetStore.closeDialogAction(widgetId, id);
    } else {
      ChatWidgetStore.openDialogAction(widgetId, id);
    }
  }, [widgetId, id, menuDialog]);

  useEffect(() => {
    if (items?.length) {
      ChatWidgetStore.addDialogAction(widgetId, {
        widgetId,
        id,
        className: dialogClassName,
        onOpen,
        onDismiss,
        onDidDismiss,
        verticalAlign: 'bottom',
        children: children ?? (
          <div className={`${classNamePrefix}__menu-wrapper`}>
            {items.map((item, index) => (
              <MenuItem key={item.id ?? index} {...item} className={clsx(`${classNamePrefix}__menu-item`, item.className)} />
            ))}
          </div>
        )
      });
    }
  }, [widgetId, children, dialogClassName, id, items, onDidDismiss, onDismiss, onOpen]);

  return toggleButton ? (
    <div className={clsx(classNamePrefix, className)} onClick={toggleOpenDialog}>
      {toggleButton}
    </div>
  ) : (
    <ButtonPrimary
      className={clsx(classNamePrefix, className)}
      style={style}
      headChild={
        menuDialog?.isOpen ? (
          <span className="material-symbols-outlined">close</span>
        ) : (
          <span className="material-symbols-outlined icon">menu</span>
        )
      }
      onClick={toggleOpenDialog}
    >
      <TranslatedMessage id="label.menu">Menu</TranslatedMessage>
    </ButtonPrimary>
  );
});
