export function insertTextAtCaret(text: string) {
  let sel, range;
  if (window.getSelection) {
    // Get the current selection
    sel = window.getSelection();
    if (sel?.getRangeAt && sel.rangeCount) {
      // Get the range of the current selection
      range = sel.getRangeAt(0);
      // Delete the current contents of the selection
      range.deleteContents();
      // Create a new text node with the provided text
      const textNode = document.createTextNode(text);
      // Insert the text node at the caret position
      range.insertNode(textNode);
      // Move the caret to the end of the inserted text
      range.setStartAfter(textNode);
      range.collapse(true);
      // Update the selection with the new range
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
}
