import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { IChatWidgetStore } from './chat-widget.type';

const initStore: IChatWidgetStore = {
  dialogs: new ObservableMap(),
  floats: new ObservableMap(),
  floatStacks: new ObservableMap()
};

export const getChatWidgetStore = createStore('chat-widget-store', initStore);
